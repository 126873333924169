<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-text="'Lista de Fluxos'"></v-card-title>
          <v-card-text>
            <v-btn color="primary" :to="{ path: 'fluxos/formulario' }"> Novo Fluxo </v-btn>
            <template>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.acoes="{ item }">                    
                  <v-btn v-show="item" icon :to="{ path: 'fluxos/1' }">
                    <v-icon color="green"> fas fa-eye </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Nome do Arquivo", value: "nome" },
        { text: "Status", value: "status" },
        { text: "Criado em", value: "criado" },
        { text: "Ações", value: "acoes" },
      ],
      desserts: [
        {
          nome: "Arquivo de processo",
          status: "Concluido",
          criado: "10/08/2021 10:30",
          acoes: true
        },
        {
          nome: "Arquivo de despacho",
          status: "Aberto",
          criado: "10/08/2021 10:30",
        },
        {
          nome: "Arquivo do condominio tal",
          status: "Em andamento",
          criado: "10/08/2021 10:30",
        },
      ],
    };
  },
};
</script>

<style></style>
